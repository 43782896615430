:root {
	--primary-background-color: #ffffff;
	--primary-text-color: #ff0000;
}

@media screen and (prefers-color-scheme: dark) {
	:root {
		--primary-background-color: #000000;
		--primary-text-color: #ffffff;
	}
}

body {
	margin: 0;
	padding: 0;
	background: #f2f1e1;
	overflow: hidden;
}
