@import "less/variables";

.sidebar {
	position: absolute;
	top: 0;
	right: 0;
	height: 100vh;
	width: @sidebarWidth;
	background: #ffffff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transform: translateX(0);
	transition: transform 500ms ease-out;
	user-select: none;
}

.sidebar--hidden {
	transform: translateX(unit((400 / 16), rem));
}

@supports (-webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%)) or (backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%)) {
	.sidebar {
		background-color: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
		-webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
	}
}
