.detail {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
	perspective: 1px;
	perspective-origin: 0% 0%;
	background: #ffffff;
}

.detail__poster {
	transform: translate3d(480px, 270px, -3px) scale(4);
	height: 180px;
}

.detail__toolbar {
	position: absolute;
	width: 320px;
	height: 180px;
	top: 0;
}

.detail__closeButton {
	display: block;
	width: 48px;
	height: 48px;
	position: sticky;
	top: 0;
	background: none;
	border: none;
	fill: #ffffff;
	outline: none;
	cursor: pointer;
}

.detail__title {
	padding: 16px;
	font-size: 24px;
	line-height: 32px;
	background: #f0f0f0;
	color: #000000;
	position: sticky;
	top: 0;
	margin: 0;
}

.detail__content {
	position: relative;
	background: #ffffff;
	padding: 0;
	margin: 0;
	top: -4px;
}

.detail__description {
	padding: 16px;
}
